<template>
  <section class="py-10   px-4 lg:px-10   border-b-2 border-white">
    <div class="flex flex-col justify-center">
      <h1 class="mt-20 md:mx-20  text-xl font-bold">Datenschutzerklärung</h1>
      <div class="mt-20 md:mx-20 ">
        <b> Datenschutz</b>
        <br />
        <br />
        <b> 1. Datenschutz auf einen Blick</b>
        <br />
        Allgemeine HinweiseDie folgenden Hinweise geben einen einfachen
        Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn
        Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
        denen Sie persönlich identifiziert werden können. Ausführliche
        Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem
        Text aufgeführten Datenschutzerklärung.
        <br />
        <br />
        <b> Datenerfassung auf dieser Website</b>
        <br />
        Wer ist verantwortlich für die Datenerfassung auf dieser Website?Die
        Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
        Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur
        Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
        <br />
        <br />
        <b> Wie erfassen wir Ihre Daten?</b>
        <br />
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben. Andere Daten werden automatisch oder nach
        Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme
        erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser,
        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
        Daten erfolgt automatisch, sobald Sie diese Website betreten.
        <br /><br />
        <b> Wofür nutzen wir Ihre Daten?</b>
        <br />
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
        <br /><br />
        <b> Welche Rechte haben Sie bezüglich Ihrer Daten?</b>
        <br />
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        <br />
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
        jederzeit an uns wenden.
        <br /><br />
        <b> Analyse-Tools und Tools von Drittanbietern</b>
        <br />
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit sogenannten
        Analyseprogrammen. Detaillierte Informationen zu diesen
        Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
        <br /><br />
        <b>2. Hosting</b>
        <br />
        Hosting mit Amazon Web Services (AWS)Wir hosten unsere Website bei AWS.
        Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F.
        Kennedy, 1855 Luxemburg (nachfolgend: AWS). Wenn Sie unsere Website
        besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS
        verarbeitet. Hierbei können auch personenbezogene Daten an das
        Mutterunternehmen von AWS in die USA übermittelt werden. Die
        Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln
        gestützt.Details finden Sie hier:
        https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.
        <br />
        Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS:
        https://aws.amazon.com/de/privacy/?nc1=f_pr.
        <br />
        Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst
        zuverlässigen Darstellung unserer Website. Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
        auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
        jederzeit widerrufbar.
        <br /><br />
        <b>Abschluss eines Vertrages über Auftragsverarbeitung</b>
        <br />
        Wir haben einen Vertrag über Auftragsverarbeitung mit AWS geschlossen.
        Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
        Vertrag, der gewährleistet, dass AWS die personenbezogenen Daten unserer
        Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
        DSGVO verarbeitet.
        <br /><br />
        <b>3. Allgemeine Hinweise und Pflichtinformationen</b>
        <br />
        <b>Datenschutz</b>
        <br />
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
        <br />
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben.Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
        <br />
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
        <br />
        Hinweis zur verantwortlichen StelleDie verantwortliche Stelle für die
        Datenverarbeitung auf dieser Website ist:
        <br />
        <br />
        ZW Systems GmbH
        <br />Konrad-Zuse-Ring 41 <br />E-Mail: <img :src="mail" />
        <br />
        <br />
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
        <br />
        Ihr Ansprechpartner in Sachen Datenschutz/unser Datenschutzbeauftragter
        <br />
        E-Mail: <img :src="mail" /> <br /><br />
        <b>Speicherdauer</b>
        <br />
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
        berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
        keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
        personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
        Fortfall dieser Gründe.
        <br /><br />
        <b>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</b>
        <br />
        Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
        oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
        diese Tools aktiv sind, können Ihre personenbezogene Daten in diese
        Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
        hin, dass in diesen Ländern kein mit der EU vergleichbares
        Datenschutzniveau garantiert werden kann. Beispielsweise sind
        US-Unternehmen dazu verpflichtet, personenbezogene Daten an
        Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
        hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
        ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf
        US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
        auswerten und dauerhaft speichern. Wir haben auf diese
        Verarbeitungstätigkeiten keinen Einfluss.
        <br /><br />
        <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
        <br />
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
        <br />
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)<br />WENN DIE DATENVERARBEITUNG AUF
        GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE
        JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
        SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
        WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
        GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
        VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
        WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN
        DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE
        SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE
        INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT
        DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
        (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        <br />
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
        ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
        <br /><br />
        <b>Beschwerderecht bei der zuständigen Aufsichtsbehörde</b>
        <br />

        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
        <br /><br />
        <b>Recht auf Datenübertragbarkeit</b>
        <br />
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
        <br /><br />
        <b>SSL- bzw. TLS-Verschlüsselung</b>
        <br />
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
        <br />
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
        <br /><br />
        <b>Auskunft, Löschung und Berichtigung</b>
        <br />

        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit an uns wenden.
        <br /><br />
        <b>Recht auf Einschränkung der Verarbeitung</b>
        <br />
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen.Hierzu können Sie sich jederzeit an
        uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht
        infolgenden Fällen:
        <br />
        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
        Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
        überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen.Wenn die Verarbeitung Ihrer personenbezogenen Daten
        unrechtmäßig geschah/geschieht, können Sie statt der Löschung die
        Einschränkung der Datenverarbeitung verlangen.Wenn wir Ihre
        personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
        Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
        benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen.Wenn Sie einen
        Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
        Abwägung zwischen Ihren und unseren Interessen vorgenommen werden.
        Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie
        das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
        Daten zu verlangen.Wenn Sie die Verarbeitung Ihrer personenbezogenen
        Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
        abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
        oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Europäischen Union oder eines
        Mitgliedstaats verarbeitet werden.
        <br /><br />
        <b>4. Datenerfassung auf dieser Website</b>
        <br /><br />

        <b>Cookies</b>
        <br />
        Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
        kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie
        werden entweder vorübergehend für die Dauer einer Sitzung
        (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
        gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese selbst löschen oder eine automatische Löschung durch Ihren
        Webbrowser erfolgt.
        <br />
        Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
        gespeichert werden, wenn Sie unsere Seite betreten
        (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
        bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
        Abwicklung von Zahlungsdienstleistungen).
        <br />
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren
        würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere
        Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
        anzuzeigen.
        <br />
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
        (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen
        erwünschter Funktionen (funktionale Cookies, z. B. für die
        Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur
        Messung des Webpublikums) erforderlich sind, werden auf Grundlage von
        Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
        Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine
        Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die
        Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
        jederzeit widerrufbar.
        <br />
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
        <br />
        Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
        werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
        gesondert informieren und ggf. eine Einwilligung abfragen.
        <br /><br />
        <b>Cookie-Einwilligung mit Borlabs Cookie.</b>
        <br />
        Unsere Website nutzt die Cookie-Consent-Technologie von Borlabs Cookie,
        um Ihre Einwilligung zur Speicherung bestimmter Cookies in Ihrem Browser
        einzuholen und diese datenschutzkonform zu dokumentieren. Anbieter
        dieser Technologie ist Borlabs – Benjamin A. Bornschein,
        Georg-Wilhelm-Str. 17, 21107 Hamburg (im Folgenden Borlabs).
        <br />
        Wenn Sie unsere Website betreten, wird ein Borlabs-Cookie in Ihrem
        Browser gespeichert, in dem die von Ihnen erteilten Einwilligungen oder
        der Widerruf dieser Einwilligungen gespeichert werden. Diese Daten
        werden nicht an den Anbieter von Borlabs Cookie weitergegeben.
        <br />
        Die erfassten Daten werden gespeichert, bis Sie uns zur Löschung
        auffordern bzw. das Borlabs-Cookie selbst löschen oder der Zweck für die
        Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungsfristen
        bleiben unberührt. Details zur Datenverarbeitung von Borlabs Cookie
        finden Sie unter
        https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/
        <br />
        Der Einsatz der Borlabs-Cookie-Consent-Technologie erfolgt, um die
        gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von Cookies
        einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. c DSGVO.
        <br /><br />
        <b>Cookie-Einstellungen anpassenServer-Log-Dateien</b>
        <br />
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-LogDateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
        <br />
        Browsertyp und Browserversionverwendetes BetriebssystemReferrer
        URLHostname des zugreifenden RechnersUhrzeit der
        ServeranfrageIP-AdresseEine Zusammenführung dieser Daten mit anderen
        Datenquellen wird nicht vorgenommen.
        <br />
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
        f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        technisch fehlerfreien Darstellung und der Optimierung seiner Website –
        hierzu müssen die Server-Log-Files erfasst werden.
        <br /><br />
        <b>Kontaktformular</b>
        <br />
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
        <br />
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
        <br />
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        <br /><br />
        <b>Anfrage per E-Mail, Telefon oder Telefax</b>
        <br />
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter.
        <br />
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
        <br />
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
        bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
        Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
        – bleiben unberührt.
        <br /><br />
        <b>5. Analyse-Tools und Werbung</b>
        <br />
        Google AnalyticsDiese Website nutzt Funktionen des Webanalysedienstes
        Google Analytics. Anbieter ist die Google Ireland Limited („Google“),
        Gordon House, Barrow Street, Dublin 4, Irland.
        <br />
        Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der
        Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber
        verschiedene Nutzungsdaten, wie z.B. Seitenaufrufe, Verweildauer,
        verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden
        von Google ggf. in einem Profil zusammengefasst, das dem jeweiligen
        Nutzer bzw. dessen Endgerät zugeordnet ist.
        <br />
        Google Analytics verwendet Technologien, die die Wiedererkennung des
        Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z.B.
        Cookies oder Device-Fingerprinting). Die von Google erfassten
        Informationen über die Benutzung dieser Website werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert.
        <br />
        Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
        Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt
        wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO; die Einwilligung ist jederzeit widerrufbar.
        <br /><br />
        <b>IP Anonymisierung</b>
        <br />
        Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
        Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten
        der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
        über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA
        gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
        von Google in den USA übertragen und dort gekürzt. Im Auftrag des
        Betreibers dieser Website wird Google diese Informationen benutzen, um
        Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten zusammenzustellen und um weitere mit der
        Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
        Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt.
        <br /><br />
        <b>Browser Plugin</b>
        <br />
        Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
        verhindern, indem Sie das unter dem folgenden Link verfügbare
        Browser-Plugin herunterladen und installieren:
        https://tools.google.com/dlpage/gaoptout?hl=de.
        <br />
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
        finden Sie in der Datenschutzerklärung von Google:
        https://support.google.com/analytics/answer/6004245?hl=de.
        <br /><br />
        <b>Widerspruch gegen Datenerfassung</b>
        <br />
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
        indem Sie die entsprechende Auswahl mittels der nachfolgenden
        Schaltfläche treffen. Nach dem Neuladen der Seite wird das Javascript
        zur Integration von Google Analytics nicht mehr geladen. Das Erfassen
        der Daten durch Google Analytics wird somit verhindert. Bestehende
        Cookies werden dabei jedoch nicht entfernt:
        <br />
        Google AnalyticsMehr Informationen zum Umgang mit Nutzerdaten bei Google
        Analytics finden Sie in der Datenschutzerklärung von Google:
        https://support.google.com/analytics/answer/6004245?hl=de.AuftragsverarbeitungWir
        haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen
        und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei
        der Nutzung von Google Analytics vollständig um.
        <br /><br />
        <b>Demografische Merkmale bei Google Analytics</b>
        <br />
        Diese Website nutzt die Funktion „demografische Merkmale“ von Google
        Analytics, um den Websitebesuchern passende Werbeanzeigen innerhalb des
        Google-Werbenetzwerks anzeigen zu können. Dadurch können Berichte
        erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der
        Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener
        Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese
        Daten können keiner bestimmten Person zugeordnet werden. Sie können
        diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem
        Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google
        Analytics wie im Punkt „Widerspruch gegen Datenerfassung“ dargestellt
        generell untersagen.
        <br /><br />
        <b>Speicherdauer</b>
        <br />
        Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit
        Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B.
        DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 14
        Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter
        folgendem Link:
        https://support.google.com/analytics/answer/7667196?hl=de
        <br /><br />
        <b>6. Plugins und Tools</b>
        <br />
        YouTube mit erweitertem DatenschutzDiese Website bindet Videos der
        YouTube ein. Betreiber der Seiten ist die Google Ireland Limited
        („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
        <br />
        Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt
        laut YouTube, dass YouTube keine Informationen über die Besucher auf
        dieser Website speichert, bevor diese sich das Video ansehen. Die
        Weitergabe von Daten an YouTube-Partner wird durch den erweiterten
        Datenschutzmodus hingegen nicht zwingend ausgeschlossen. So stellt
        YouTube – unabhängig davon, ob Sie sich ein Video ansehen – eine
        Verbindung zum Google DoubleClick-Netzwerk her.
        <br />
        Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
        Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
        YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn
        Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube,
        Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies
        können Sie verhindern, indem Sie sich aus Ihrem YouTubeAccount
        ausloggen.
        <br />
        Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies
        auf Ihrem Endgerät speichern oder vergleichbare
        Wiedererkennungstechnologien (z. B. Device-Fingerprinting) einsetzen.
        Auf diese Weise kann YouTube Informationen über Besucher dieser Website
        erhalten. Diese Informationen werden u. a. verwendet, um
        Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern
        und Betrugsversuchen vorzubeugen.
        <br />
        Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
        Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen Einfluss
        haben.
        <br />
        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
        entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
        Einwilligung ist jederzeit widerrufbar.
        <br />
        Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
        Datenschutzerklärung unter: https://policies.google.com/privacy?hl=de.
        <br /><br />
        <b>Google Web Fonts (lokales Hosting)</b>
        <br />
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Die Google
        Fonts sind lokal installiert. Eine Verbindung zu Servern von Google
        findet dabei nicht statt.
        <br />
        Weitere Informationen zu Google Web Fonts finden Sie unter
        https://developers.google.com/fonts/faq und in der Datenschutzerklärung
        von Google: https://policies.google.com/privacy?hl=de.
        <br /><br />
        <b>Font Awesome (lokales Hosting)</b>
        <br />
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font
        Awesome. Font Awesome ist lokal installiert. Eine Verbindung zu Servern
        von Fonticons, Inc. findet dabei nicht statt.
        <br />
        Weitere Informationen zu Font Awesome finden Sie in der
        Datenschutzerklärung für Font Awesome unter:
        https://fontawesome.com/privacy.
        <br /><br />
        <b>Google Maps</b>
        <br />
        Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google
        Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4,
        Irland.
        <br />
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
        IP-Adresse zu speichern. Diese Informationen werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert. Der
        Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
        Wenn Google Maps aktiviert ist, kann Google zum Zwecke der einheitlichen
        Darstellung der Schriftarten Google Web Fonts verwenden. Beim Aufruf von
        Google Maps lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
        <br />
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
        Darstellung unserer OnlineAngebote und an einer leichten Auffindbarkeit
        der von uns auf der Website angegebenen Orte. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO; die Einwilligung ist jederzeit widerrufbar.
        <br />
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt.
        <br /><br />
        <b>Details finden Sie hier:</b>
        <br />
        <a
          target="_blank"
          href="https://privacy.google.com/businesses/gdprcontrollerterms/"
          >https://privacy.google.com/businesses/gdprcontrollerterms/</a
        >
        <br />
        und <br />
        <a
          target="_blank"
          href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
          >https://privacy.google.com/businesses/gdprcontrollerterms/sccs/</a
        >
        <br />
        <br />
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Google:
        <br />
        <a target="_blank" href="https://policies.google.com/privacy?hl=de">
          https://policies.google.com/privacy?hl=de </a
        >.
      </div>
    </div>
  </section>
</template>

<script>
import mail from "@/assets/mail.png";

export default {
  name: "Privacy",
  components: {},
  setup() {
    return {
      mail,
    };
  },
};
</script>
